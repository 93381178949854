.teaserbox{
	background-color: $blue-light;
	text-align: center;
	border: 1px solid white;
	position: relative;
	transition: background-color 0.3s ease;
	@media screen and (max-width: $bp-sm - 1) {
		text-align: left;
	}
	&:hover{
		background-color: $white;
		.teaserbox-title{
			color: $black;
			margin-left: -1.5em;
			&:after{
				width: 1em;
			}
			&:before{
				opacity: 0.5;
				transform: translateY(0px);
			}
		}
	}
}
.teaserbox-link{
	position: absolute;
	display: block;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 5;
}
.teaserbox-title{
	transition: margin 0.3s ease;
	font-family: $ff2;
	font-size: 2rem;
	display: inline-block;
	margin: 4em 0;
	position: relative;
	color: $blue-dark;
	@media screen and (max-width: $bp-sm - 1) {
		margin: 2em 0;
		font-size: 1.5rem;
	}
	&:after{
		transition: width 0.3s ease;
		content: "";
		background-color: $blue;
		margin-left: 0.5em;
		position: absolute;
		width: 0;
		height: 3px;
		top: 51%;
	}
	&:before{
		transition: opacity 0.4s 0.1s ease, transform 0.2s 0.1s ease;
		transform: translateY(3px);
		content: "erfahren Sie mehr";
		position: absolute;
		font-size: 1rem;
		bottom: -1.5em;
		left: 0;
		white-space: nowrap;
		opacity: 0;
	}
	
}
