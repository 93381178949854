$blue: #009EE2;
$blue-light: #EBF8FD;
$blue-dark: #10485F;
$black: #000;
$white: #FFF;

$font-base: 16px;

$column-gap: 2rem;
$site-gap: 2rem;

$bp-lg: 1200px;
$bp-md: 992px;
$bp-sm: 768px;
$bp-xsm: 468px;

$site-width: 55rem;


$ff1: Arial, sans-serif;
$ff2: "PxGroteskRegular", sans-serif;

@import 'normalize';
@import 'mixins';
@import 'grid';

@import 'modules/text';
@import 'snippets/elements';
@import 'snippets/teaserbox';


.home-text{
	padding-top: 2rem;
	padding-bottom: 2rem;
	font-size: 2rem;
	line-height: 1.2em;
	font-family: $ff2;
	span{
		color: $blue;
	}
}

.bodywrapper {
  height: 100%;
  display: table;
  width: 100%;
  table-layout: fixed;
}
.box, .l-tc {
  display: table-cell;
}

.header, .l-tr {
  display: table-row;
  height: 1px;
}

.header{
	height:160px;
}

.main, .l-t {
  height: 100%;
  display: table;
  width: 100%;
}
 
.footer{
	display: table-row;
	height:1px;
	.site-width{
		padding-top: 2rem;
		padding-bottom: 2rem;
	}
	.footer-line{
		height: 5px;
		background-color: $blue;
		width: 100%;
	}
}

.module{
	margin: 4.5rem 0;
	@media screen and (max-width: $bp-sm - 1) {
		margin: 2.5rem 0;
	}
	&.text-3-columns{
		margin: 0.5rem 0;
	}
}

html, body{
	font-size: $font-base;
	font-family: $ff1;
	height: 100%;
	‑webkit‑text‑size‑adjust: none;
}
img{
	width: 100%;
	height: auto;
}
a{
	color: $blue;
}
p{
	margin: 0;
}

.ta-r{
	text-align: right;
}
.ta-l{
	text-align: left;
}
.m-ta-r{
	@media screen and (max-width: $bp-sm - 1) {
		text-align: right;
	}
}
.lazyload,.lazyloading {
	
}
.lazyloaded {
	background-color: transparent;
}

.site-width{
	max-width: $site-width + $site-gap;
	margin: 0 auto;
	box-sizing: border-box;
	padding: 0 $site-gap/2;
}

.full-width{
	background:$blue-light;
	padding-bottom: 4.5rem;
	padding-top:4.5rem;
	margin-top: 0;
	margin-bottom: 0;
	@media screen and (max-width: $bp-sm - 1) {
		padding-bottom:  2.5rem;
		padding-top: 2.5rem;
	}
	.module-text--text{
		font-size:20px;
		font-family: $ff2;

 	}
	.module-text--title{
		font-size:32px;
		padding-bottom: 1em;
	}
}

.module-text--title{
	padding-bottom: 1em;
	font-size:21px;
	@media screen and (max-width: $bp-sm - 1) {
		padding-bottom: 0.75em;
	}
}


.text-3-columns{
	.row > div{
		margin-top: 2rem;
		margin-bottom: 2rem;
		&:after{
			content: "";
			position: absolute;
			right: $column-gap/2;
			top: 0;
			width: 2px;
			height: 100%;
			background-color: $blue;
			@media screen and (max-width: $bp-sm - 1  ) {
				right: $site-gap/2;
				top: auto;
				bottom: 0;
				width: auto;
				height: 2px;
				left: $site-gap/2;
			}
		}
		&:last-of-type{
			&:after{
				content: none;
			}
		}
	}
}

table{
	width:100%;
	thead{
		background:$blue-light;
		th{
			text-align:left;
			padding:10px;
		}
		tr{
			td{
				width:auto;
			}
		}
	}
 
 	tbody{
 		tr{
			border-bottom:1px solid $blue-light;
 			td{
			padding:10px;
 			}
 		}
 	}
}


// NAV

$toggleBarHeight: 3px;

.header--sticky{
	padding-top: 24px;
	padding-bottom: 22px;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	background-color: $white;
	z-index: 10;
}
.header--flow{
	padding-top:80px;
}

.header--nav{
 	font-family: $ff2;
	font-size:16px;
	
	ul{
		list-style-type: none;
		margin: 0;
		padding: 0;
		float:right;
		
		li{
			float:left;
			
			a{
				text-decoration: none;
				color: $black;
				display: block;
				margin:0px 20px 0 20px;
				padding-bottom: 8px;
				
				&:hover, &.active  {
					color: $black;
					border-bottom: solid 2px $blue;
				}
			}
 
		}	
 
	}
 
}



.row-flex {
	display: flex;
	flex-wrap: wrap;
}


 

.header--logo{
	width: 157px;
	height: 27px;
}
.header--logo-sub{
	width: 180px;
	height: 39px;
}

.header--nav-toggler{
	position: relative;
	display: inline-block;
	height: 25px;
	width: 30px;
	cursor:pointer;
	.nav-toggler--line{
		position: absolute;
		left: 0;
		width: 100%;
		height: $toggleBarHeight;
		background-color: $blue;
	}
	.nav-toggler--line{
		
		transition: top 0.2s 0.2s ease, opacity 0s 0.1s linear, transform 0.1s 0s ease, margin 0s 0.2s linear;
		&:nth-of-type(1){
			top: 0;
		}
		&:nth-of-type(2){
			top: 50%;
			margin-top: -$toggleBarHeight/2;
		}
		&:nth-of-type(3){
			top: 100%;
			margin-top: -$toggleBarHeight;
		}
	}
	&.is-open{
		.nav-toggler--line{
			transition: top 0.2s ease, opacity 0s 0.3s linear, transform 0.1s 0.4s ease;
			&:nth-of-type(1){
				top: 50%;
				margin-top: -$toggleBarHeight/2;
				transform: rotateZ(-45deg);
			}
			&:nth-of-type(2){
				opacity: 0;
			}
			&:nth-of-type(3){
				top: 50%;
				margin-top: -$toggleBarHeight/2;
				transform: rotateZ(45deg);
			}
		}
	}
}

.google--maps{
	#map{
		height:440px;
		background:$blue-light;
	}
}


.site-box{
	.member{
		min-height: 350px;
	}
	.module-box{
		display: flex;
		align-items: center;
		
		img{
			padding-top:50px;
			vertical-align: middle;
			max-width:150px;
			height:auto;
			display:block;
			margin:auto;
		}
		
		&:hover{
			opacity:0.8;
		}
 
	}

	
	.module-box-text{
		background:white;
	}
	
	.col-sm-3{
		width:33.3%;
		height:auto;
		
		.col-style{
			background:white;
		}
	}
	
	
	.col-sm-12{
		background:$blue-light;
	}
	
	p{
		text-align:center;
		font-size:16px;
		font-family:$ff2;
		padding:15px 15px 12px 15px;
		letter-spacing:0.05em;
		color:$black;
 
 
	}
	span{
		text-align:center;
		font-size: 0.9rem;
		font-family:$ff2;
		display: block;
		color:gray;
		letter-spacing:0.05em;
		line-height: 1.3rem;
		padding-bottom: 30px;
	}
	
}

 

.header--team{
	
	height:400px;
	max-height: 400px;
	background:$blue-light;
	overflow:hidden;
	
	.imgholder{
		float:right;
		padding-top: 65px;
		padding-right:50px;
	}
 
	.holder{
		display: inline-block;
		
		span{
			text-align:left;
			font-size:18px;
			font-family:$ff2;
			padding:15px 15px 12px 0px;
			letter-spacing:0.05em;
			color:$black;
		}
		
		p{
			text-align:left;
			font-size:32px;
			font-family:$ff2;
			padding:105px 15px 20px 0px;
			letter-spacing:0.05em;
			color:$black;
		}
	}
 
	img{
		width:100%;
		height:auto;
	}

}


 
 
@media screen and (max-width: $bp-sm - 1  ) {
	.header{
		height:155px;
	}
	.header--nav-mobile{
		display:block;
		position:absolute;
		left:0;
		right:0;
		top:85px;
		background:$blue-light;
		z-index:10;
		overflow: hidden;
		height:0px;
		
		ul{
			padding: 50px 0px 50px 0px;
			list-style:none;
			li{
				padding:20px;
				text-align: center;
				
				a{
					text-decoration:none;
					color:$blue-dark;
					font-size:24px;
					font-family:$ff2;
 
					&:hover, &.active  {
							color:$blue-dark;
							border-bottom:2px solid $blue;
					}
				}
			}
		}	
	}
	
	.header--team .holder{
		width: 100%;
		display: inline-block;
	}

	.header--team{
		.imgholder{
			padding-top:0px;
			padding-right:0px;
		}
	}
 
	 
}

 


@media screen and (min-width: $bp-sm - 1  ) {

	.header--nav-mobile{
		display:none;
	}
 
}


@media screen and (max-width: $bp-sm - 1  ) {
	
	.header--sticky{
		padding-top:30px;
		padding-bottom:20px;
	}	
	
	.module-box{
		.col-sm-3{
			width:100%;
			padding-bottom:60px;
		}
	}
	a{
		text-decoration:none;
	}
	
}

.show-mobile-nav{
	height: calc(100vh - 80px);
	position: fixed;
	top: 80px;
	left: 0;
	width: 100%;

	transition: height 0.3s ease;
}


// FOOTER
.footer{
	font-family: $ff2;
	font-size: 0.85rem;
}
.logo-footer{
	width: 104px;
	height: 50px;
}
.footer--sponsor{
	width: 88px;
	height: 21px;
}


.richtext{
	line-height: 1.5em;
	h1{
		font-size: 1.4em;
		font-weight: bold;
		padding-top: 1rem;
	}
	ul{
		margin: 0;
		padding: 0;
		list-style-position: outside;
		padding-left: 1rem;
	}
	img{
		width: 100%;
	 
	}
	figure{
		margin: 0;
		padding: 0;
	}
	p{
		margin: 0;
		padding: 0;
		padding-bottom:30px;
		&:last-of-type{
			padding-bottom: 0;
		}
	}
	ul{
		list-style-type: none;
		padding: 0;
		li{
			padding-left: 1.75em;
			position: relative;
			&:before{
				position: absolute;
				left: 0;
				content: "—";
				color: $blue;
				font-weight: bold;
			}
		}
	}
	strong{
		font-weight:bold;
	}
}

.mobile-hidden{
	@media screen and (max-width: $bp-sm - 1) {
		display: none!important;
	}
}
.desktop-hidden{
	@media screen and (min-width: $bp-sm) {
		display: none!important;
	}
}